import { ConfigProvider, theme } from "antd";
import "./App.scss";
import Header from "./components/Header";
import Resource from "./components/Resource";
import { data } from "./data";

function App() {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
      }}
    >
      <div className="App">
        <Header />
        <div className="resources">
          {data.map((resource) => (
            <Resource resource={resource} key={resource.email} />
          ))}
        </div>
      </div>
    </ConfigProvider>
  );
}

export default App;
