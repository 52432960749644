import { DownloadOutlined } from "@ant-design/icons";
import { Button, Flex, List, Skeleton, Typography } from "antd";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import { ArgentiProjectInvoice } from "../types/ArgentiResource.types";

const moneyFormatter = Intl.NumberFormat("en-AU", {
  currency: "AUD",
  style: "currency",
  maximumFractionDigits: 0,
});

const InvoiceList = ({ invoices }: { invoices: ArgentiProjectInvoice[] }) => {
  return (
    <div
      id="scrollableDiv"
      style={{
        overflow: "auto",
      }}
    >
      <InfiniteScroll
        dataLength={invoices.length}
        next={() => {}}
        hasMore={false}
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        endMessage={<></>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={invoices}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <Flex
                justify="space-between"
                style={{ width: "100%" }}
                gap={"middle"}
              >
                <Typography.Text style={{ flex: "1 1 0px" }}>
                  {item.description}
                </Typography.Text>

                <Typography.Text style={{ flex: "1 1 0px" }}>
                  {moneyFormatter.format(item.amount)}
                </Typography.Text>

                <Typography.Text style={{ flex: "1 1 0px" }}>
                  {dayjs(item.date).format("DD-MM-YYYY")}
                </Typography.Text>

                <Button
                  type="primary"
                  shape="round"
                  icon={<DownloadOutlined />}
                  size={"small"}
                  onClick={() => window.alert("Heya, not yet...")}
                >
                  Download
                </Button>
              </Flex>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default InvoiceList;
