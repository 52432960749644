import { Breakpoint, Grid } from "antd";
import ConditionalRender from "./ConditionalRender";
import { useEffect, useState } from "react";

function ConditionalRenderBreakpoint({
  children,
  breakpoints,
}: {
  children: React.ReactNode;
  breakpoints: {
    from?: Breakpoint;
    to?: Breakpoint;
  };
}) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    // Check if any 'from' or 'to' breakpoint is provided in the props
    if (breakpoints.from || breakpoints.to) {
      const fromScreen = breakpoints.from ? screens[breakpoints.from] : true;
      const toScreen = breakpoints.to ? screens[breakpoints.to] : true;

      setShouldRender(!!fromScreen && !!toScreen);
    }
  }, [screens, breakpoints]);

  console.log({ screens });

  return (
    <ConditionalRender condition={shouldRender}>{children}</ConditionalRender>
  );
}

export default ConditionalRenderBreakpoint;
