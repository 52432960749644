import { ArrowDownOutlined, MailFilled } from "@ant-design/icons";
import { Avatar, Button, Typography } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { motion } from "framer-motion";
import { useState } from "react";
import "../styles/resource.scss";
import { ArgentiResource } from "../types/ArgentiResource.types";
import Breakdown from "./Breakdown";
import Tags from "./Tags";
import ConditionalRenderBreakpoint from "./ConditionalRenderBreakpoint";

dayjs.extend(relativeTime);

function Resource({ resource }: { resource: ArgentiResource }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <div className="resource">
      <div className="details">
        <div className="personal">
          <ConditionalRenderBreakpoint
            breakpoints={{
              from: "md",
            }}
          >
            {resource.pic ? (
              <Avatar
                shape="square"
                size={148}
                src={resource.pic}
                style={{ flexShrink: "0" }}
              />
            ) : (
              <Avatar
                shape="square"
                size={148}
                style={{
                  backgroundColor: "#fde3cf",
                  color: "#f56a00",
                  flexShrink: "0",
                }}
              >
                <Typography.Title style={{ color: "#f56a00" }}>
                  {resource.firstName[0] + resource.lastName[0]}
                </Typography.Title>
              </Avatar>
            )}
          </ConditionalRenderBreakpoint>
          <div>
            <Typography.Title level={1}>
              {resource.firstName} {resource.lastName}
            </Typography.Title>
            <p>{resource.role}</p>
            <Tags tags={resource.tags ?? []} style={{ margin: "1em 0" }} />
          </div>
        </div>
        <div className="actions">
          <a href={`mailto:${resource.email}`}>
            <Button
              type="link"
              shape="circle"
              icon={<MailFilled color="white" />}
              size={"large"}
            />
          </a>

          {resource.projects && resource.projects.length ? (
            <Button
              shape="circle"
              icon={<ArrowDownOutlined rotate={isExpanded ? 180 : 0} />}
              size={"large"}
              onClick={() => setIsExpanded(!isExpanded)}
              ghost
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {isExpanded && resource.projects && resource.projects ? (
        <motion.div
          className="breakdown"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          {resource.projects.map((project) => (
            <Breakdown project={project} key={project.name} variants={item} />
          ))}
        </motion.div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Resource;
