import { Progress, Statistic, Typography } from "antd";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { ArgentiProject } from "../types/ArgentiResource.types";
import ConditionalRender from "./ConditionalRender";
import InvoiceList from "./InvoiceList";
import Milestones from "./Milestones";

function Breakdown({
  project,
  ...props
}: {
  project: ArgentiProject;
  [key: string]: any;
}) {
  const budgetPercent = Math.round(
    (project.budgetSpent / project.budgetAllocated) * 100
  );

  // Define your start date, end date, and current date
  const startDate = dayjs(project.contractStartDate); // Replace with your start date
  const endDate = dayjs(project.contractEndDate); // Replace with your end date
  const currentDate = dayjs(); // Current date

  // Calculate the total number of days between the start and end dates
  const totalDays = endDate.diff(startDate, "day");

  // Calculate the number of days completed from the start date to the current date
  const daysCompleted = currentDate.diff(startDate, "day");

  // Calculate the percentage of date completion
  const percentageCompletion = Math.round((daysCompleted / totalDays) * 100);

  return (
    <motion.div {...props}>
      <Typography.Title level={2}>{project.name}</Typography.Title>
      <Typography.Text type="secondary">{project.description}</Typography.Text>
      <ConditionalRender condition={!!project.milestones}>
        <Milestones
          milestones={project.milestones!}
          currentMilestoneId={project.currentMilestoneId ?? 0}
          style={{ margin: "20px 0" }}
        />
      </ConditionalRender>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "1fr",
          gridTemplateColumns: "30% auto",
          gap: "1em",
        }}
      >
        <div style={{ width: "100%" }}>
          <Typography.Title level={4}>Breakdown</Typography.Title>
          <Typography.Text>Budget allocation:</Typography.Text>
          <Progress percent={budgetPercent} />
          <Typography.Text>Time allocation:</Typography.Text>
          <Progress
            percent={Math.max(0, Math.min(100, percentageCompletion))}
          />
          <Statistic
            title="Account Balance (AUD)"
            value={project.budgetAllocated - project.budgetSpent}
            precision={2}
          />
        </div>
        <div>
          <Typography.Title level={4}>Invoicing</Typography.Title>
          <ConditionalRender condition={!!project.invoices}>
            <InvoiceList invoices={project.invoices!} />
          </ConditionalRender>
        </div>
      </div>
    </motion.div>
  );
}

export default Breakdown;
