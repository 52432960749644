import { ArgentiResource, ContactPerson } from "./types/ArgentiResource.types";

const yousseff: ContactPerson = {
  email: "yousseff.bardouh@argenti.com.au",
  firstName: "Yousseff",
  lastName: "Bardouh",
};

const simonLaw: ContactPerson = {
  firstName: "Simon",
  lastName: "Law",
  email: "simon.law1@det.nsw.edu.au",
};

const maryPoppins: ContactPerson = {
  firstName: "Mary",
  lastName: "Poppins",
  email: "mary.poppins3@det.nsw.edu.au",
};

export const data: ArgentiResource[] = [
  {
    firstName: "Adrian",
    lastName: "Gabardo",
    role: "Senior Software Engineer",
    email: "adrian.gabardo@argenti.com.au",
    projects: [
      {
        name: "AssessedIT",
        contractStartDate: "2023-05-01T00:00:00.000Z",
        contractEndDate: "2023-08-29T00:00:00.000Z",
        budgetAllocated: 100000,
        budgetSpent: 42000,
        contactPersons: [simonLaw, maryPoppins],
        currentMilestoneId: 2,
        milestones: [
          {
            id: 0,
            title: "Project Kickoff",
            description: "Project started",
            date: "2023-05-01T00:00:00.000Z",
          },
          {
            id: 1,
            title: "Backend Development",
            description: "Backend development started",
            date: "2023-05-15T00:00:00.000Z",
          },
          {
            id: 2,
            title: "Frontend Development",
            description: "Frontend development started",
            date: "2023-06-01T00:00:00.000Z",
          },
          {
            id: 3,
            title: "Project Handover",
            description: "Project handover to client",
            date: "2023-08-29T00:00:00.000Z",
          },
        ],
        description: `AssessedIT is a platform that allows teachers to create and share assessments with other teachers.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam magna, lobortis vitae eros a, euismod pharetra lectus. Vivamus in mi a diam sodales viverra quis eget ex. Sed euismod eros eu nibh pretium malesuada. Sed a mollis lorem, eget eleifend lectus. Phasellus ac dictum diam. Nam aliquet justo sagittis aliquet interdum. Aenean eget odio nec ante interdum consectetur id a ante. Aliquam aliquet metus ipsum, sit amet ornare augue rhoncus sit amet. 
          `,
        invoices: [
          {
            id: 1,
            description: "Invoice 1",
            amount: 10000,
            date: "2023-05-01T00:00:00.000Z",
          },
          {
            id: 2,
            description: "Invoice 2",
            amount: 20000,
            date: "2023-06-01T00:00:00.000Z",
          },
          {
            id: 3,
            description: "Invoice 3",
            amount: 30000,
            date: "2023-07-01T00:00:00.000Z",
          },
          {
            id: 4,
            description:
              "Adrian is Awesome Adrian is Awesome Adrian is Awesome Adrian is super awesome",
            amount: 30059,
            date: "2023-07-01T00:00:00.000Z",
          },
          {
            id: 5,
            description: "Invoice 5",
            amount: 13550,
            date: "2023-07-01T00:00:00.000Z",
          },
        ],
      },
      {
        name: "Class Dashboard",
        contractStartDate: "2023-05-01T00:00:00.000Z",
        contractEndDate: "2023-11-23T00:00:00.000Z",
        contactPersons: [simonLaw],
        budgetAllocated: 89000,
        budgetSpent: 52000,
        description: `Class Dashboard is a platform that allows teachers to create and share assessments with other teachers. 
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam magna, lobortis vitae eros a, euismod pharetra lectus. Vivamus in mi a diam sodales viverra quis eget ex. Sed euismod eros eu nibh pretium malesuada. Sed a mollis lorem, eget eleifend lectus. Phasellus ac dictum diam. Nam aliquet justo sagittis aliquet interdum. Aenean eget odio nec ante interdum consectetur id a ante. Aliquam aliquet metus ipsum, sit amet ornare augue rhoncus sit amet. 
        `,
        invoices: [
          {
            id: 1,
            description: "Invoice 1",
            amount: 10000,
            date: "2023-05-01T00:00:00.000Z",
          },
          {
            id: 2,
            description: "Invoice 2",
            amount: 20000,
            date: "2023-06-01T00:00:00.000Z",
          },
          {
            id: 3,
            description: "Invoice 3",
            amount: 30000,
            date: "2023-07-01T00:00:00.000Z",
          },
        ],
      },
    ],
    manager: yousseff,
    pic: "https://media.licdn.com/dms/image/D5603AQHdLem3ctI_9g/profile-displayphoto-shrink_800_800/0/1691992977458?e=1704326400&v=beta&t=CKbpP7kgvpEbIzA2BKv1NO-PxIo-WdAwqf0eNVW_yLs",
    tags: [
      "Javascript",
      "React",
      "Node",
      "Vue",
      "MySQL",
      "PostgreSQL",
      "MongoDB",
      "AWS",
    ],
  },
  {
    firstName: "Danijel",
    lastName: "Boskovic",
    role: "Software Engineer",
    email: "danijel.boskovic@argenti.com.au",
    projects: [
      {
        name: "AssessedIT",
        contractStartDate: "2022-09-01T00:00:00.000Z",
        contractEndDate: "2023-12-29T00:00:00.000Z",
        contactPersons: [simonLaw],
        budgetAllocated: 100000,
        budgetSpent: 63000,
        description: `AssessedIT is a platform that allows teachers to create and share assessments with other teachers.
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam magna, lobortis vitae eros a, euismod pharetra lectus. Vivamus in mi a diam sodales viverra quis eget ex. Sed euismod eros eu nibh pretium malesuada. Sed a mollis lorem, eget eleifend lectus. Phasellus ac dictum diam. Nam aliquet justo sagittis aliquet interdum. Aenean eget odio nec ante interdum consectetur id a ante. Aliquam aliquet metus ipsum, sit amet ornare augue rhoncus sit amet. 
          `,
        currentMilestoneId: 2,
        milestones: [
          {
            id: 0,
            title: "Project Kickoff",
            description: "Project started",
            date: "2023-05-01T00:00:00.000Z",
          },
          {
            id: 1,
            title: "Backend Development",
            description: "Backend development started",
            date: "2023-05-15T00:00:00.000Z",
          },
          {
            id: 2,
            title: "Frontend Development",
            description: "Frontend development started",
            date: "2023-06-01T00:00:00.000Z",
          },
          {
            id: 3,
            title: "Project Handover",
            description: "Project handover to client",
            date: "2023-08-29T00:00:00.000Z",
          },
        ],
        invoices: [
          {
            id: 1,
            description: "Invoice 1",
            amount: 10000,
            date: "2023-05-01T00:00:00.000Z",
          },
          {
            id: 2,
            description: "Invoice 2",
            amount: 20000,
            date: "2023-06-01T00:00:00.000Z",
          },
          {
            id: 3,
            description: "Invoice 3",
            amount: 30000,
            date: "2023-07-01T00:00:00.000Z",
          },
        ],
      },
      {
        name: "Class Dashboard",
        contractStartDate: "2023-05-01T00:00:00.000Z",
        contractEndDate: "2023-08-29T00:00:00.000Z",
        contactPersons: [simonLaw, maryPoppins],
        budgetAllocated: 83000,
        budgetSpent: 56000,
        description: `Class Dashboard is a platform that allows teachers to create and share assessments with other teachers. 
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc quam magna, lobortis vitae eros a, euismod pharetra lectus. Vivamus in mi a diam sodales viverra quis eget ex. Sed euismod eros eu nibh pretium malesuada. Sed a mollis lorem, eget eleifend lectus. Phasellus ac dictum diam. Nam aliquet justo sagittis aliquet interdum. Aenean eget odio nec ante interdum consectetur id a ante. Aliquam aliquet metus ipsum, sit amet ornare augue rhoncus sit amet. 
        `,
        currentMilestoneId: 2,
        milestones: [
          {
            id: 1,
            title: "Bug Fixes",
            description: "Backend development started",
            date: "2023-05-15T00:00:00.000Z",
          },
          {
            id: 2,
            title: "Frontend Development",
            description: "Frontend development started",
            date: "2023-06-01T00:00:00.000Z",
          },
          {
            id: 3,
            title: "QA Testing",
            description: "Quality assurance testing of new features",
            date: "2023-08-29T00:00:00.000Z",
          },
          {
            id: 4,
            title: "QA Approval",
            description: "Quality assurance approval",
            date: "2023-09-10T00:00:00.000Z",
          },
          {
            id: 5,
            title: "Project Handover",
            description: "Project handover to client",
            date: "2023-09-29T00:00:00.000Z",
          },
        ],
        invoices: [
          {
            id: 1,
            description: "Invoice 1",
            amount: 10000,
            date: "2023-05-01T00:00:00.000Z",
          },
          {
            id: 2,
            description: "Invoice 2",
            amount: 20000,
            date: "2023-06-01T00:00:00.000Z",
          },
          {
            id: 3,
            description: "Invoice 3",
            amount: 30000,
            date: "2023-07-01T00:00:00.000Z",
          },
        ],
      },
    ],
    manager: yousseff,
  },
  {
    firstName: "Brandon",
    lastName: "Chee",
    role: "Uhhh...",
    email: "brandon.chee@argenti.com.au",
    projects: [],
    manager: yousseff,
  },
];
