import { Flex, Tag } from "antd";

function Tags({ tags, ...props }: { tags: string[]; [key: string]: any }) {
  return (
    <Flex {...props} wrap="wrap" gap={2}>
      {tags.map((tag) => (
        <Tag bordered={false} key={tag}>
          {tag}
        </Tag>
      ))}
    </Flex>
  );
}

export default Tags;
