import { Steps } from "antd";
import { ArgentiProjectMilestone } from "../types/ArgentiResource.types";
import dayjs from "dayjs";

function Milestones({
  milestones,
  currentMilestoneId,
  ...props
}: {
  milestones: ArgentiProjectMilestone[];
  currentMilestoneId: number;
  [key: string]: any;
}) {
  return (
    <Steps
      size="small"
      current={currentMilestoneId}
      items={milestones.map((milestone) => ({
        title: milestone.title,
        description: milestone.date
          ? dayjs(milestone.date).format("DD/MM/YYYY")
          : milestone.description,
      }))}
      {...props}
    />
  );
}

export default Milestones;
